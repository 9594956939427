<template>
  <div>
    <div class="content_block">
      <query-form label-width="100px" @search="handleSearch" @reset="handleReset">
        <el-form-item label="联系人姓名：">
          <el-input v-model="query.name" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="所属医院：">
          <el-input v-model="query.customerName" placeholder="请输入所属医院"></el-input>
        </el-form-item>
      </query-form>
    </div>
    <table-with-pagination :tableData="contactList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactApi from '@/api/contact';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';

export default {
  name: 'CONTACT_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  computed: {
    ...mapGetters([
      'actionPermissionMap',
    ]),
  },
  data () {
    return {
      columnList: [
        {
          tooltip: true,
          label: '联系人姓名',
          minWidth: '160px',
          prop: 'name',
          render: (h, {row})=>{
            const isPermission = this.actionPermissionMap && this.actionPermissionMap['customer:contactPerson:view'];
            let clickFn = isPermission ? this.handleActionClick.bind(this, row, 'detail') : () => {};
            let classObj = {'text-primary': isPermission, 'cursor-pointer': isPermission};

            return <span class={classObj} onClick={clickFn}>{row.name}</span>;
          },
        },
        {
          tooltip: true,
          label: '所属医院',
          minWidth: '150px',
          prop: 'customerName',
          render: (h, {row})=>{
            const isPermission = this.actionPermissionMap && this.actionPermissionMap['customer:view'];
            let clickFn = isPermission ? this.handleActionClick.bind(this, row, 'customer') : () => {};
            let classObj = {'text-primary': isPermission, 'cursor-pointer': isPermission};

            return <span class={classObj} onClick={clickFn}>{row.customerName}</span>;
          },
        },
        {
          tooltip: true,
          label: '手机号',
          minWidth: '150px',
          prop: 'phone',
        },
        {
          tooltip: true,
          label: '部门',
          minWidth: '150px',
          prop: 'department',
        },
        {
          tooltip: true,
          label: '职位',
          minWidth: '150px',
          prop: 'position',
        },
        {
          tooltip: true,
          label: '是否默认联系人',
          minWidth: '150px',
          prop: 'isDefault',
          render: (h, { row }) => {
            const content = row.isDefault ? '是' : '否';
            return h('span', content);
          },
        },
        {
          tooltip: true,
          label: '联系地址',
          minWidth: '150px',
          prop: 'defaultContactAddress',
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            if (row.canDelete) {
              actions.push(<span v-auth="customer:contactPerson:edit" class='cursor-pointer text-primary ml-1 ' onClick={this.handleActionClick.bind(this, row, 'edit')}>编辑</span>);
              actions.push(<span v-auth="customer:contactPerson:delete" class='cursor-pointer text-primary ml-1 table-action table-action-delete' onClick={this.handleActionClick.bind(this, row, 'delete')}>删除</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
      query: {
        status: null,
        name: '',
      },
      contactList: [],
      type: '',
      tableButtons: [
        {
          name: '新增', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          permission: 'customer:contactPerson:add',
          click: ()=> {
            this.type = 'add';

            this.$router.push({
              path: '/contact/add',
              query: {
                type: 'add',
              },
            });
          },
        },
      ],
    };
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        status: null,
        name: '',
      };
      this.handleSearch();
    },
    getTableList () {
      ContactApi.getContactList({...this.query, ...this.pagination, pageNum: this.pagination.currentPage}).then((res) =>{
        this.contactList = res.body.records;
        this.pagination.total = res.body.total;
      });
    },
    handleActionClick (data, type) {
      this.type = type;

      if (type === 'customer') {
        this.$router.push({
          path: '/customer/detail',
          query: {
            type: 'detail',
            id: data.customerId,
          },
        });
      } else if (type === 'delete') {
        this.$confirm('此操作将永久删除联系人, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            ContactApi.deleteContact(data.id).then(() => {
              this.$message.success('删除成功');
            });
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
      } else {
        this.$router.push({
          path: '/contact/detail',
          query: {
            type,
            id: data.id,
          },
        });
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'CONTACT_DETAIL') {
      switch (this.type) {
      case 'detail':
        to.meta.title = '医院联系人详情';
        break;
      case 'edit':
        to.meta.title = '编辑联系人';
        break;
      default:
        break;
      }
    }

    next();
  },
};
</script>
